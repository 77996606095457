import React, { useState } from 'react'
import { Box, IconButton, Text } from '@chakra-ui/react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { translates } from '../../utils/languague'
import { useLanguage } from '../../providers/LanguageProvider'

export default function Adult({ quantAdult, setQuantAdult }) {
  const { language } = useLanguage()
  return (
    <Box margin="10px" display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" flexDirection="column" mt="10px" alignItems="center">
        <Text fontWeight="bold" fontSize="16px">
          {translates(language)?.searchbox?.default?.item8}
        </Text>
        <Box margin="10px" display="flex" flexDirection="row">
          <IconButton
            onClick={() => setQuantAdult(old => (old > 0 ? old - 1 : old))}
            colorScheme="red"
            size="sm"
            aria-label="Remove Adult"
            icon={<BiMinus />}
          />
          <Box ml="5px" mr="5px">
            <Text fontWeight="bold" fontSize="20px">
              {quantAdult}
            </Text>
          </Box>
          <IconButton
            onClick={() => setQuantAdult(old => (old < 8 ? old + 1 : old))}
            colorScheme="red"
            size="sm"
            aria-label="Add Adult"
            icon={<BiPlus />}
          />
        </Box>
      </Box>
    </Box>
  )
}
