import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { OpenRoutes } from './open.routes';
import { PrivateRoutes } from './private.routes';
import { RequireAuth } from './require.auth';
import DepositoDias from '../pages/Admin/DepositoDias';
// import Empreendimentos from '../pages/Admin/Empreendimentos'
// import ExtratoPontos from '../pages/Admin/ExtratoPontos'
import InfoPessoais from '../pages/Admin/InfoPessoais';
import Search from '../pages/Search';
import Details from '../pages/Details';
import Checkout from '../pages/Checkout';
import Contato from '../pages/Admin/Contato';
import Ajuda from '../pages/Admin/Ajuda';

import BookSuccess from '../pages/BookSuccess';
import DashboardNew from '../pages/Admin/DashboardNew';
import Exchanges from '../pages/Exchanges';
import UsersCadastroPage from '../pages/Users/UsersCadastro';
import Balance from '../pages/Admin/Balance';
import Enterprises from '../pages/Enterprises';
import Finances from '../pages/Finances';
import ExchangesDetails from '../pages/Exchanges/ExchangesDetails';
import OwnershipDetails from '../pages/Enterprises/OwnershipDetails';
import TransactionsDetails from '../pages/Finances/TransactionsDetails';
import Whois from '../pages/Whois';
import ExchangesAdm from '../pages/ExchangesAdm';

const AppRouter = () => (
  <Routes>
    {OpenRoutes.map((item, key) => (
      <Route key={`Routes-${Number(key)}`} path={item?.path} element={<item.component />} />
    ))}
    <Route path="/" element={<RequireAuth />}>
      {PrivateRoutes?.map((item, key) => {
        const ComponentCreate = item?.componentCreate as unknown as React.ElementType;
        const ComponentShow = item?.componentShow as unknown as React.ElementType;
        const ComponentEdit = item?.componentEdit as unknown as React.ElementType;
        return (
          <React.Fragment key={Number(key)}>
            <Route path={item.path} element={<item.component />} />
            {/* PANEL */}
            <Route path="/panel/dashboard" element={<DashboardNew />} />
            <Route path="/panel/users/:id/edit" element={<UsersCadastroPage />} />
            <Route path="/panel/reservas/:id" element={<ExchangesDetails />} />
            <Route path="/panel/reservas" element={<Exchanges />} />
            <Route path="/panel/weeks" element={<DepositoDias />} />
            <Route path="/panel/propriedades" element={<Enterprises />} />
            <Route path="/panel/intercambios" element={<ExchangesAdm />} />
            <Route path="/panel/propriedades/:id" element={<OwnershipDetails />} />
            <Route path="/panel/extrato-cashclubs" element={<Balance />} />
            <Route path="/panel/finances" element={<Finances />} />
            <Route path="/panel/finances/:id" element={<TransactionsDetails />} />
            {/* OTHERS */}
            <Route path="/search" element={<Search />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/bookSuccess" element={<BookSuccess />} />
            <Route path="/:id/details" element={<Details />} />
            <Route path="/info-pessoais" element={<InfoPessoais />} />
            <Route path="/contact" element={<Contato />} />
            <Route path="/whois" element={<Whois />} />
            <Route path="/help" element={<Ajuda />} />
            {ComponentCreate && <Route path={`${item?.path}/create`} element={<ComponentCreate />} />}
            {ComponentShow && <Route path={`${item?.path}/:itemId`} element={<ComponentShow />} />}
            {ComponentEdit && <Route path={`${item?.path}/:itemId/edit`} element={<ComponentEdit />} />}
          </React.Fragment>
        );
      })}
    </Route>
  </Routes>
);

export default AppRouter;
