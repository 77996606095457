import React, { useState } from 'react'
import { Box, Flex, InputGroup, InputRightElement, Text } from '@chakra-ui/react'
import { BiSearch } from 'react-icons/bi'
import { AutoComplete, AutoCompleteInput, AutoCompleteList } from '@choc-ui/chakra-autocomplete'
import { useLanguage } from '../../providers/LanguageProvider'
import { translates } from '../../utils/languague'

export default function DestinationSearchAirport({
  inputReference,
  verifySrc,
  onChanged,
  destinations,
  loadingD,
  setDestinationID,
  setSearch,
}) {
  const { language } = useLanguage()

  const handleFocus = item => {
    inputReference.current.focus({ focusVisible: false })
    inputReference.current.value = `${item}`
  }

  return (
    <>
      <InputGroup zIndex={2}>
        <AutoComplete freeSolo emptyState="" closeOnBlur onSuspend={false}>
          <AutoCompleteInput
            value={inputReference?.current?.value}
            ref={inputReference}
            focusBorderColor={(verifySrc !== '' || verifySrc?.length > 20) && 'red'}
            borderColor={(verifySrc !== '' || verifySrc?.length > 20) && 'red'}
            placeholder={translates(language)?.searchbox?.default?.item2}
            onChange={async e => {
              setSearch(e?.target?.value)
              if (destinations !== [] && (await e?.target?.value?.length) >= 3) {
                onChanged(e?.target?.value)
                setSearch(e?.target?.value)
              } else {
                setSearch(translates(language)?.filters?.item24)
              }
            }}
          />
          <AutoCompleteList>
            <Box display="flex" alignSelf="center" alignItems="center" justifyContent="flex-start">
              {destinations?.length === 0 && !loadingD && translates(language)?.searchbox?.default?.item14}
              {loadingD ? translates(language)?.searchbox?.default?.item13 : ''}
            </Box>

            {destinations?.map((destination, idx) => (
              <Flex
                p="8px"
                key={`Destination - ${idx}`}
                _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                onClick={() => {
                  setDestinationID(destination?.id)
                  handleFocus(`${destination?.name[language]}`)
                  setSearch(`${destination?.name[language]}`)
                }}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                  {/* <Box>{destination?.iata}</Box> */}
                  <Text fontSize="14px">{`${destination?.name[language]}`}</Text>
                </Box>
              </Flex>
            ))}
          </AutoCompleteList>
        </AutoComplete>
        <InputRightElement
          pointerEvents="none"
          // eslint-disable-next-line react/no-children-prop
          children={<BiSearch color="gray.300" />}
        />
      </InputGroup>
    </>
  )
}
