/* eslint-disable */

import { AxiosStatic, AxiosRequestConfig } from 'axios'
import { jwtDecode } from 'jwt-decode' // Importação corrigida
import { getAuthStorage, removeAuthStorage } from '../services/storage'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const axiosInstance = require('axios') as AxiosStatic

interface DecodedToken {
  exp: number
  // Adicione outras propriedades do token aqui, se necessário
}

const getToken = async () => {
  try {
    const storageAuth = await getAuthStorage()
    if (storageAuth) {
      return storageAuth.token
    }
    return null
  } catch (error) {
    throw error
  }
}

const addTokenToRequest = async (config: AxiosRequestConfig) => {
  try {
    const token = await getToken()

    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`

      // Decodifica o token para obter o tempo de expiração
      const decoded: DecodedToken = jwtDecode(token)
      console.log('Token decodificado:', decoded)

      const currentTime = Math.floor(Date.now() / 1000)

      // Se o token já expirou, faça o logout
      if (decoded.exp < currentTime) {
        console.log('Token expirado. Realizando logout.')
        Logout()
        window.location.href = '/login'
        return Promise.reject(new Error('Token expirado'))
      }
    }

    return config
  } catch (error) {
    console.error('Erro ao adicionar token à requisição:', error)
    return Promise.reject(error)
  }
}

const apiCPT = axiosInstance.create({
  baseURL: process.env.REACT_APP_API_CPT_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

// Adicionar o token diretamente ao cabeçalho
apiCPT.interceptors.request.use(addTokenToRequest)

apiCPT.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      // Se o status for 401, o token é inválido ou expirou, deslogar o usuário
      console.log('Erro 401 detectado. Realizando logout.')
      Logout()
      window.location.href = '/login'
    }

    return Promise.reject(error)
  }
)

const apiCNG = axiosInstance.create({
  baseURL: `${process.env.REACT_APP_API_CANGOOROO_URL}`,
})

const apiCommons = axiosInstance.create({
  baseURL: `${process.env.REACT_APP_API_COMMMONS_DETAILS}`,
})

const apiVindiPublicKey = axiosInstance.create({
  baseURL: `${process.env.REACT_APP_API_VINDI_PUBLIC}`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `${process.env.REACT_APP_KEY_VINDI_PUBLIC}`,
  },
})

const apiVindiPublic = axiosInstance.create({
  url: `${process.env.REACT_APP_API_VINDI_PUBLIC}/payment_profiles`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `${process.env.REACT_APP_KEY_VINDI_PUBLIC}`,
  },
})

function Logout() {
  removeAuthStorage()
}

const apiAirportAutocomplete = axiosInstance.create({
  baseURL: '',
})

export { apiCPT, apiCNG, apiCommons, apiVindiPublic, apiVindiPublicKey, apiAirportAutocomplete }
