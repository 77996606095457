import React from 'react';
import { translates } from '../../utils/languague';
import { useLanguage } from '../../providers/LanguageProvider';
import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { CURRENT_PROJECT_VERSION } from '../../utils/projectVersion';
import { CgMail } from 'react-icons/cg';

const Logo = () => {
  return <Image src="/assets/logo.svg" maxW={256} />;
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  const { language } = useLanguage();
  return (
    <Box
      mt="30px"
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      borderY={'1px'}
      borderColor={'gray.200'}
    >
      <Container as={Stack} maxW={1280} py={10}>
        <SimpleGrid templateColumns={['1fr', '1fr 1fr 1fr']} spacing={8}>
          <Stack spacing={6}>
            <Box>
              <Logo color={useColorModeValue('gray.700', 'white')} />
            </Box>
            <Text fontSize={'sm'}>© 2024 Club Share. Todos os direitos reservados</Text>
            <Text fontSize={'sm'}>{`v${CURRENT_PROJECT_VERSION}`}</Text>
            <Stack direction={'row'} spacing={6}>
              <SocialButton label={'Facebook'} target="_blank" href={'https://www.facebook.com/clubsharebr'}>
                <FaFacebook />
              </SocialButton>

              <SocialButton label={'YouTube'} target="_blank" href={' https://www.youtube.com/@clubsharebr'}>
                <FaYoutube />
              </SocialButton>
              <SocialButton label={'Instagram'} target="_blank" href={'https://www.instagram.com/clubsharebr/'}>
                <FaInstagram />
              </SocialButton>
              {/* <SocialButton label={'Linkedin'} target="_blank" href={'https://br.linkedin.com/company/clubshare'}>
                <FaLinkedin />
              </SocialButton> */}
              <SocialButton label={'Email'} target="_blank" href={`mailto:atendimento@clubshare.com.br`}>
                <CgMail />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>{translates(language)?.footer?.box2?.menu1?.title}</ListHeader>
            <Link target="_blank" href={'/whois'}>
              {translates(language)?.footer?.box2?.menu1?.item1}
            </Link>
            <Text>Telefone: +55 62 3089 2500</Text>
            <Text>
              Email:{' '}
              <Link label={'Email'} target="_blank" href={`mailto:atendimento@clubshare.com.br`}>
                atendimento@clubshare.com.br
              </Link>
            </Text>
            <Text>CNPJ: 54.409.673/0001-29</Text>
            <Text>Endereço: Rua 72, 325, Ed. Trend Office, 10º andar Jardim Goiás, Goiânia - GO CEP: 74.805-480</Text>
            {/* <Link href={'#'}>{translates(language)?.footer?.box2?.menu1?.item2}</Link>
            <Link href={'#'}>{translates(language)?.footer?.box2?.menu1?.item3}</Link>
            <Link href={'#'}>{translates(language)?.footer?.box2?.menu1?.item4}</Link> */}
          </Stack>
          {/* <Stack align={'flex-start'}>
            <ListHeader>{translates(language)?.footer?.box2?.menu2?.title}</ListHeader>
            <Link href={'#'}>{translates(language)?.footer?.box2?.menu2?.item1}</Link>
            <Link href={'#'}>{translates(language)?.footer?.box2?.menu2?.item2}</Link>
            <Link href={'#'}>{translates(language)?.footer?.box2?.menu2?.item3}</Link>
            <Link href={'#'}>{translates(language)?.footer?.box2?.menu2?.item4}</Link>
          </Stack> */}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
