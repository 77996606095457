/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-children-prop */
import React, { SetStateAction, useState } from 'react';
import moment from 'moment';
import { Input } from '@chakra-ui/react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
// import { IoMdCalendar } from 'react-icons/io';

registerLocale('pt-BR', ptBR);

export const DateFilter = ({ onChange, placeholder }: any) => {
  const [dateValue, setDateValue] = useState<Date | null>(null);

  return (
    <DatePicker
      dateFormat="dd/MM/yyyy"
      locale="pt-BR"
      // selectsStart={selectsStart}
      // selectsEnd={selectsEnd}
      // startDate={selectsStart ? field.value : dateStart ?? null}
      // endDate={selectsEnd ? field.value : dateEnd ?? null}
      onChange={(date: moment.MomentInput) => {
        setDateValue((date || null) as SetStateAction<Date | null>);
        onChange(date ? moment(date).format('yyyy-MM-DD') : null);
      }}
      selected={dateValue}
      showTimeSelect={false}
      todayButton="Hoje"
      customInput={
        <Input boxShadow="0px 5px 10px #2D3748" borderRadius="full" ml="20px" background="theme.light.bodyInput" />
      }
      dropdownMode="select"
      placeholderText={placeholder}
      isClearable
      shouldCloseOnSelect
    />
  );
};
