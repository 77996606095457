// Components/Enterprises.tsx
import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Icon, Select, Text, useMediaQuery } from '@chakra-ui/react';
import { VscChecklist } from 'react-icons/vsc';
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton';
import { ReturnButton } from '../../components/Buttons/ReturnButton';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '../../components/DataGrid';
import AlertModal from '../../components/AlertModal';
import { InfoButton } from '../../components/Buttons/InfoButton';
import moment from 'moment';
// import { SearchFilter } from '../../components/inputs/filters/search';
// import { DateFilter } from '../../components/inputs/filters/date';
// import { StatusFilter } from '../../components/inputs/filters/status';
import { useOwnershipData } from '../../fetchs/ownership';

const ClientEnterprises: React.FC = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>({});
  const [status, setStatus] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 700px)', {
    ssr: true,
    fallback: true,
  });

  useEffect(() => {
    const storedUserData = localStorage.getItem('__user_data');
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }
  }, []);

  const { data, requestApiCPT, loading } = useOwnershipData(userData?.user_id);

  const [requestMade, setRequestMade] = useState(false);

  useEffect(() => {
    const fetchOwnershipData = async () => {
      if (userData.user_id !== undefined && !requestMade && !loading) {
        await requestApiCPT('get');
        setRequestMade(true);
      }
    };

    fetchOwnershipData();
  }, [userData.user_id, requestApiCPT, loading, requestMade]);

  const baseUrl = `v1/ownership/${userData?.user_id}`;
  const columns = [
    { name: 'enterprise.enterprise_name', label: 'Nome Empresa' },
    { name: 'start_at', label: 'Início da semana' },
    { name: 'end_at', label: 'Fim da semana' },
    { name: 'user_review_cpt', label: 'Status' },
  ];

  return (
    <Flex flexDir="column" ml={isMobile ? '0' : '4vw'} w={isMobile ? '100%' : '73vw'}>
      <Box display="flex" flexDir="column">
        <Box
          display="flex"
          alignContent="center"
          justifyItems="center"
          justifySelf="center"
          alignItems="center"
          flexDir="row"
          mb="20px"
        />
      </Box>
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Box>
          <Flex align="center" mb={5}>
            <ReturnButton previousPage="panel/dashboard" />
            <Heading as="h1" size="lg">
              Propriedades
            </Heading>
          </Flex>
          <DataGrid
            isId={null}
            columns={columns}
            requestApiCPT={requestApiCPT}
            paginationData={data && data}
            rows={data?.ownership || []}
            error={'Error'}
            baseUrl={baseUrl}
            data={data != null && data?.ownership}
            // hasCreate
            // hasCreateUrl="/enterprises/create"
            loading={loading}
            infoButton={[
              {
                name: 'steps',
                button: (
                  <AlertModal
                    alertMsg={
                      <Flex align="center">
                        <Icon as={VscChecklist} color="#1890FF" mr="5px" fontSize="25px" />
                        <Text>Clique no ícone para listar as etapas de reservas</Text>
                      </Flex>
                    }
                    msgStatus="info"
                    ButtonOpen={InfoButton}
                  />
                ),
              },
            ]}
            columnWithAction={[
              {
                name: 'steps',
                columnAction: (rowItem: {
                  id: any;
                  total_comment:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                }) => (
                  <Flex align="center">
                    <IconGeneralButton
                      Icon={VscChecklist}
                      color="#1890FF"
                      fontSize="25px"
                      onClick={() => navigate(`${rowItem.id}`)}
                    />
                    <Text fontWeight="bold">{rowItem.total_comment}</Text>
                  </Flex>
                ),
              },
            ]}
            rowActions={{ show: true, edit: false, delete: false }}
            formatColum={[
              {
                field: 'created_at',
                formatField: (value: moment.MomentInput) => moment(value).format('DD/MM/yyyy'),
              },
              {
                field: 'start_at',
                formatField: (value: moment.MomentInput) => moment(value).format('DD/MM/yyyy hh:mm'),
              },
              {
                field: 'end_at',
                formatField: (value: moment.MomentInput) => moment(value).format('DD/MM/yyyy hh:mm'),
              },
              {
                field: 'updated_at',
                formatField: (value: moment.MomentInput) => moment(value).format('DD/MM/yyyy hh:mm'),
              },

              {
                field: 'user_review_cpt',
                formatField: (value: any, item: any) => {
                  if (item) {
                    if (item.user_review_cpt > 0 && item.user_review_enterprise > 0) {
                      return (
                        <span
                          style={{ color: 'white', padding: 4, background: 'green', borderRadius: 5, fontSize: 14 }}
                        >
                          Aprovado
                        </span>
                      );
                    } else if (item.user_review_cpt === null && item.user_review_enterprise === null) {
                      return (
                        <span style={{ color: 'white', padding: 4, background: 'gray', borderRadius: 5, fontSize: 14 }}>
                          Disponível
                        </span>
                      );
                    } else if (item.user_review_cpt === 0 && item.user_review_enterprise === 0) {
                      return (
                        <span style={{ color: 'white', padding: 4, background: 'red', borderRadius: 5, fontSize: 14 }}>
                          Pendente pra ambos
                        </span>
                      );
                    } else if (item.user_review_cpt === 0 && item.user_review_enterprise > 0) {
                      return (
                        <span
                          style={{ color: 'white', padding: 4, background: 'orange', borderRadius: 5, fontSize: 14 }}
                        >
                          Pendente Hotel
                        </span>
                      );
                    } else if (item.user_review_cpt > 0 && item.user_review_enterprise === 0) {
                      return (
                        <span
                          style={{ color: 'white', padding: 4, background: '#e0e000', borderRadius: 5, fontSize: 14 }}
                        >
                          Pendente Clubshare
                        </span>
                      );
                    } else {
                      return (
                        <span style={{ color: 'white', padding: 4, background: 'red', borderRadius: 5, fontSize: 14 }}>
                          Reprovado
                        </span>
                      );
                    }
                  }
                  return null;
                },
              },
            ]}
            // headerFilters={[
            //   {
            //     name: 'search',
            //     filterComponent: SearchFilter,
            //   },
            //   {
            //     name: 'startDate',
            //     filterComponent: DateFilter,
            //     placeholder: 'Selecionar data inicial',
            //   },
            //   {
            //     name: 'endDate',
            //     filterComponent: DateFilter,
            //     placeholder: 'Selecionar data final',
            //   },
            //   {
            //     name: 'status',
            //     filterComponent: StatusFilter,
            //     placeholder: 'Status',
            //   },
            // ]}
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default ClientEnterprises;
