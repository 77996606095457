import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Text, Select, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactTable from '../../../components/ReactTable';
import { apiCPT } from '../../../services/api';
import DashboardCardAdmin from '../../../components/DashboardCard';

const DashboardNew = () => {
  const userData = JSON.parse(localStorage.getItem('__user_data') || '{}');
  const navigate = useNavigate();
  const toast = useToast();
  const [dados2, setDados2] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState('pending_hotel'); // Filtro inicial para "Pendente Hotel"
  const [enterprise_name, setEnterprise_name] = useState('');
  const [ownership_id, setOwnership_id] = useState('');
  const [user_firstname, setUser_firstname] = useState('');
  const [dataV, setDataV] = useState('');
  const [enterpriseIds, setEnterpriseIds] = useState<number[]>(userData?.enterprises || []);
  const [loggedInUserId] = useState(userData?.user_id || null); // ID do usuário logado

  // Executa a busca ao carregar a página e quando o filtro ou paginação mudar
  useEffect(() => {
    getInfos();
  }, [statusFilter, pageNumber, perPage]);

  // Função para buscar os dados filtrados
  const getInfos = async () => {
    try {
      const getData2 = await apiCPT.post(`v1/filter/ownership?pageNumber=${pageNumber}&perPage=${perPage}`, {
        onwership_id: ownership_id,
        enterprise_name: enterprise_name,
        user_firstname: user_firstname,
        start_at: dataV.length === 10 ? moment(dataV, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
        enterprise_ids: enterpriseIds,
        status: statusFilter, // Aplica o filtro de status
      });

      if (getData2.status === 200) {
        setDados2(getData2.data.ownership);
        setPageSize(getData2.data.paginateCount);
      }
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    }
  };

  // Função para renderizar os status com estilos e botão de Aprovar
  const columns = React.useMemo(
    () => [
      {
        Header: 'Empreendimento',
        columns: [
          {
            Header: 'Nome',
            accessor: 'enterprise.enterprise_name',
          },
        ],
      },
      {
        Header: 'Info',
        columns: [
          {
            Header: 'Unidade Habitacional',
            accessor: 'habitational_unity.room_type',
          },
          {
            Header: 'Data de entrada',
            accessor: 'start_at',
            Cell: ({ row }: any) => {
              return (
                <Text>
                  {moment(new Date(row?.original?.start_at?.toString()))?.format('DD/MM/YYYY')?.toString() ?? ''}
                </Text>
              );
            },
          },
          {
            Header: 'Data de saída',
            accessor: 'end_at',
            Cell: ({ row }: any) => {
              return (
                <Text>
                  {moment(new Date(row?.original?.end_at?.toString()))?.format('DD/MM/YYYY')?.toString() ?? ''}
                </Text>
              );
            },
          },
        ],
      },
      {
        Header: 'Aprovação Empresa',
        Cell: ({ row }: any) => {
          const ownership = row.original;
          const {
            onwership_id,
            user_id,
            enterprise_id,
            which_one_hu,
            habitational_unity_id,
            user_review_cpt,
            user_review_enterprise,
            start_at,
            end_at,
          } = ownership;

          const [loading, setLoading] = useState(false);

          // Função para aprovar quando o status for pendente
          const handleApproval = async () => {
            setLoading(true);

            const payload = {
              ownership_id: onwership_id,
              user_id: user_id,
              enterprise_id: enterprise_id,
              which_one_hu: which_one_hu,
              habitational_unity_id: habitational_unity_id,
              user_review_cpt: user_review_cpt,
              user_review_enterprise: loggedInUserId, // ID do usuário logado para aprovação
              start_at: start_at,
              end_at: end_at,
            };

            try {
              const response = await apiCPT.put(`/v1/ownership/put/${onwership_id}`, payload);

              if (response.status !== 200) {
                throw new Error('Erro na aprovação');
              }
              toast({
                title: `Aprovação realizada com sucesso!`,
                position: 'top',
                status: 'success',
                isClosable: true,
              });
              getInfos(); // Atualiza a lista após a aprovação
            } catch (error) {
              console.error(error);
              toast({
                title: `Erro na aprovação`,
                position: 'top',
                status: 'error',
                isClosable: true,
              });
            } finally {
              setLoading(false);
            }
          };

          // Função para renderizar o status com estilos e botões
          const getStatusElement = (label: string, bgColor: string, borderColor: string) => (
            <Box
              padding="4px 8px"
              backgroundColor={bgColor}
              color="white"
              border={`1px solid ${borderColor}`}
              borderRadius="5px"
              display="inline-block"
              fontSize="14px"
            >
              {label}
            </Box>
          );

          // Exibir status e botão para "Pendente Hotel"
          if (user_review_enterprise === null) {
            return getStatusElement('Não depositada / Aguardando', 'grey', 'darkgrey');
          } else if (user_review_enterprise === 0) {
            // Exibe o status como "Pendente Hotel" com o botão de Aprovar
            return (
              <Box display="flex" alignItems="center" gap={3}>
                {getStatusElement('Pendente Hotel', 'orange', 'darkorange')}
                <Button
                  onClick={handleApproval}
                  colorScheme="green"
                  size="sm"
                  isLoading={loading}
                  loadingText="Aprovando..."
                  spinnerPlacement="end"
                  borderRadius="md"
                  boxShadow="md"
                  _hover={{ bg: 'green.500' }}
                  _active={{ bg: 'green.600' }}
                >
                  Aprovar
                </Button>
              </Box>
            );
          } else if (user_review_enterprise !== 0 && user_review_enterprise !== null) {
            return getStatusElement('Aprovado', 'green', 'darkgreen');
          } else {
            return getStatusElement('Desconhecido', 'red', 'darkred');
          }
        },
      },
    ],
    [loggedInUserId], // Dependência atualizada para garantir que o ID do usuário logado seja usado corretamente
  );

  return (
    <>
      {Number(userData.user_type) === 3 ? (
        <>
          <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
            <DashboardCardAdmin />
          </Flex>
        </>
      ) : (
        <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
          <Box mb="20px">
            <Select
              placeholder="Filtrar por status"
              onChange={e => setStatusFilter(e.target.value)}
              value={statusFilter} // Filtro inicia com "pending_hotel"
            >
              <option value="">Todos</option>
              <option value="both">Pendente Ambos</option>
              <option value="pending_hotel">Pendente Hotel</option>
              <option value="pending_clubshare">Pendente Clubshare</option>
              <option value="available">Disponível</option>
              <option value="approved">Depósito Aprovado</option>
            </Select>
          </Box>

          <Box>
            <ReactTable
              columns={columns}
              pageNumber={pageNumber}
              data={dados2}
              totaPages={pageSize}
              onChange={(e: any) => {
                setPageNumber(e.pageIndex + 1);
                setPerPage(e.pageSize);
              }}
            />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default DashboardNew;
