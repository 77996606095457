/* eslint-disable react/no-children-prop */
import React from 'react';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';

export const SearchFilter = ({ onChange, ...rest }: any) => {
  return (
    <InputGroup maxWidth="350px">
      <Input
        placeholder="Buscar"
        variant="outline"
        borderRadius="full"
        onChange={e => onChange(e.target.value)}
        background="#FAFAFA"
        boxShadow="0px 3px 6px #2D3748"
        {...rest}
      />
      <InputRightElement pointerEvents="none" children={<FaSearch color="gray.300" />} />
    </InputGroup>
  );
};
