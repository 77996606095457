import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import ScrollToTop from 'react-scroll-to-top';
import { CotationProvider } from './providers/CotationProvider';
import { LanguageProvider } from './providers/LanguageProvider';
import { SearchProvider } from './providers/SearchProvider';
import { theme } from './theme';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { SearchDestinationVoosProvider } from './providers/SearchDestinationVoosProvider';

const ScrollToTopWithDynamicStyle = () => {
  const location = useLocation();
  const isRootPage = location.pathname === '/';

  return (
    <ScrollToTop
      smooth
      component={<p style={{ color: 'red' }}>↑</p>}
      style={!isRootPage ? { right: '20px', left: 'auto' } : { bottom: 90, left: '20px', right: 'auto' }}
    />
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <LanguageProvider>
          <CotationProvider>
            <SearchProvider>
              <SearchDestinationVoosProvider>
                <App />
                <ScrollToTopWithDynamicStyle />
              </SearchDestinationVoosProvider>
            </SearchProvider>
          </CotationProvider>
        </LanguageProvider>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
