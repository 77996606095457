import React from 'react';

import {
  Flex,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { PaginationType } from '../../hooks/useFetchCPT';

interface PaginatorProps {
  pagination: PaginationType;
  queryParams: any;
  setQueryParams: any;
}

export const Paginator = ({ pagination, queryParams, setQueryParams }: PaginatorProps) => {
  return (
    <Flex justifyContent="end" m={4} alignItems="center">
      <Flex>
        <Tooltip label="Primeira página">
          <IconButton
            onClick={() => setQueryParams((old: any) => ({ ...old, pageNumber: 1 }))}
            background="theme.light.bodyInput"
            // boxShadow="0px 5px 10px #2D3748"
            border="1px solid"
            borderColor="grey"
            isDisabled={!pagination?.has_prev}
            icon={<ArrowLeftIcon h={3} w={3} />}
            mr={4}
            aria-label=""
          />
        </Tooltip>
        <Tooltip label="Página anterior">
          <IconButton
            onClick={() => setQueryParams((old: any) => ({ ...old, pageNumber: old.pageNumber - 1 }))}
            background="theme.light.bodyInput"
            // boxShadow="0px 5px 10px #2D3748"
            border="1px solid"
            borderColor="grey"
            isDisabled={!pagination?.has_prev}
            icon={<ChevronLeftIcon h={6} w={6} />}
            mr={4}
            aria-label=""
          />
        </Tooltip>
      </Flex>

      <Flex alignItems="center">
        <Text flexShrink="0" mr={8}>
          Página{' '}
          <Text fontWeight="bold" as="span">
            {pagination?.offset}
          </Text>{' '}
          de{' '}
          <Text fontWeight="bold" as="span">
            {pagination?.paginateCount}
          </Text>
        </Text>
        <Text flexShrink="0">Ir para página:</Text>{' '}
        <NumberInput
          ml={2}
          mr={8}
          w={28}
          min={1}
          max={pagination?.paginateCount}
          value={pagination?.offset}
          isDisabled={pagination?.offset == 1 && !pagination?.hasMore}
          onChange={(value: string) => {
            const pageNumber = Number(value) - 1 ? Number(value) : 1;
            setQueryParams((old: any) => ({ ...old, pageNumber }));
          }}
          background="theme.light.bodyInput"
          // borderRadius="5px"
          // boxShadow="0px 5px 10px #2D3748"
          // border="1px solid"
          // borderColor="grey"
          defaultValue={1}
        >
          <NumberInputField border="1px solid" borderColor="grey" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Select
          w="150px"
          value={queryParams?.perPage}
          background="theme.light.bodyInput"
          // boxShadow="0px 5px 10px #2D3748"
          border="1px solid"
          borderColor="grey"
          defaultValue={10}
          onChange={e => {
            setQueryParams((old: any) => ({ ...old, perPage: Number(e.target.value) }));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex>
        <Tooltip label="Próxima página">
          <IconButton
            onClick={() => setQueryParams((old: any) => ({ ...old, pageNumber: old.pageNumber + 1 }))}
            background="theme.light.bodyInput"
            // boxShadow="0px 5px 10px #2D3748"
            border="1px solid"
            borderColor="grey"
            isDisabled={!pagination?.hasMore}
            icon={<ChevronRightIcon h={6} w={6} />}
            ml={4}
            aria-label=""
          />
        </Tooltip>
        <Tooltip label="Ultima página">
          <IconButton
            onClick={() => setQueryParams((old: any) => ({ ...old, pageNumber: pagination?.paginateCount }))}
            background="theme.light.bodyInput"
            // boxShadow="0px 5px 10px #2D3748"
            border="1px solid"
            borderColor="grey"
            isDisabled={!pagination?.hasMore}
            icon={<ArrowRightIcon h={3} w={3} />}
            ml={4}
            aria-label=""
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
