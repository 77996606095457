type UrlType = { url: string }

export const IFrameVoos = ({ url }: UrlType) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
      }}
    >
      <iframe id="iframe-passagens" src={url} style={{ width: '100%', height: '100%' }} frameBorder="0"></iframe>
    </div>
  )
}
