import React, { useState } from 'react'
import { Box, IconButton, Text } from '@chakra-ui/react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { translates } from '../../utils/languague'
import { useLanguage } from '../../providers/LanguageProvider'

export default function Baby({ babyQuant, setBabyQuant }) {
  const { language } = useLanguage()

  return (
    <Box margin="10px" display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" mt="10px" alignItems="center">
          <Text fontWeight="bold" fontSize="16px">
            {translates(language)?.searchBoxVoos?.babies}
          </Text>
          <Box margin="10px" display="flex" flexDirection="row">
            <IconButton
              onClick={async () => setBabyQuant(old => (old > 0 ? old - 1 : old))}
              colorScheme="red"
              size="sm"
              aria-label="Remove Children"
              icon={<BiMinus />}
            />
            <Box ml="5px" mr="5px">
              <Text fontWeight="bold" fontSize="20px">
                {babyQuant}
              </Text>
            </Box>
            <IconButton
              onClick={async () => setBabyQuant(old => (old < 3 ? old + 1 : old))}
              colorScheme="red"
              size="sm"
              aria-label="Add Children"
              icon={<BiPlus />}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
