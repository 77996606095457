import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
  Container,
  Center,
  HStack,
  Menu,
  Avatar,
  MenuDivider,
  MenuList,
  MenuItem,
  MenuButton,
  PopoverCloseButton,
  PopoverBody,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons';

import { iconCotNav, iconNav } from '../../utils/languague';
import { useLanguage } from '../../providers/LanguageProvider';
import { useCotation } from '../../providers/CotationProvider';

export default function DrawlerNavBar() {
  const { isOpen, onToggle } = useDisclosure();
  const userLocalStorageData = JSON.parse(localStorage.getItem('__user_data'));
  const [dados, setDados] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setDados({
      nome: userLocalStorageData ? userLocalStorageData?.user_firstname : '',
      sobrenome: userLocalStorageData ? userLocalStorageData?.user_lastname : '',
      avatar: userLocalStorageData ? userLocalStorageData?.user_image : '',
      saldoCashClubN: userLocalStorageData ? Math.floor(userLocalStorageData?.saldoN) : 0,
      saldoCashClubI: userLocalStorageData ? Math.floor(userLocalStorageData?.saldoI) : 0,
    });
  }, []);

  useEffect(() => {
    if (
      JSON.parse(localStorage?.getItem('language')) == null ||
      JSON.parse(localStorage?.getItem('language') == undefined) ||
      JSON.parse(localStorage?.getItem('cotation')) == null ||
      JSON.parse(localStorage?.getItem('cotation') == undefined)
    ) {
      if (
        JSON.parse(localStorage?.getItem('language')) == null ||
        JSON.parse(localStorage?.getItem('language') == undefined)
      ) {
        localStorage.setItem('language', JSON.stringify('pt-BR'));
      }
      if (
        JSON.parse(localStorage?.getItem('cotation')) == null ||
        JSON.parse(localStorage?.getItem('cotation') == undefined)
      ) {
        localStorage.setItem('cotation', JSON.stringify('BRL'));
      }
      // window.location.reload()
    }
  }, []);

  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      py={{ base: 2 }}
      borderBottom={1}
      borderStyle={'solid'}
      borderColor={useColorModeValue('gray.200', 'gray.900')}
      width="100%"
    >
      <Container maxW={1280} px={[4, 4, 4]}>
        <Flex color={useColorModeValue('gray.600', 'white')} minH={'60px'} align={'center'} w="100%">
          <Flex flex={{ base: 1, md: 'auto' }} ml={{ base: -2 }} display={{ base: 'flex', md: 'none' }}>
            <IconButton
              onClick={onToggle}
              icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <Link href={'/'}>
              <Image src="/assets/logo.svg" width={180} />
            </Link>

            <Flex alignItems="center" display={{ base: 'none', md: 'flex' }} justify="end" ml={10}>
              <DesktopNav />
            </Flex>
          </Flex>

          <Stack alignItems="center" flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'} spacing={6}>
            {dados.nome === '' ? (
              <>
                <Button
                  onClick={() =>
                    navigate('/login', {
                      state: { isBacktoHotel: false },
                    })
                  }
                  fontSize={['sm', 'md']}
                  fontWeight={500}
                >
                  Entrar
                </Button>
                <Button
                  as={'a'}
                  display={{ base: 'none', md: 'inline-flex' }}
                  fontSize={['sm', 'md']}
                  fontWeight={500}
                  href={'/register'}
                >
                  Cadastro
                </Button>
              </>
            ) : (
              <Menu>
                <MenuButton
                  as={Button}
                  size={'lg'}
                  cursor={'pointer'}
                  rightIcon={<ChevronDownIcon />}
                  borderRadius="full"
                  variant="outline"
                  px={{ base: 2, md: 6 }}
                >
                  <HStack spacing={{ base: 0, md: 4 }}>
                    <Box textAlign={'left'} display={{ base: 'none', md: 'block' }}>
                      <Text fontSize={'sm'} fontWeight={500} color={'gray.500'}>
                        {dados.nome}
                      </Text>
                      <Text fontSize={'sm'} fontWeight={500} color={'gray.600'}>
                        {Math.ceil(dados.saldoCashClubN + dados.saldoCashClubI) ?? '0'} VX Total
                      </Text>
                    </Box>

                    <Avatar size={'sm'} src={'/assets/icons/p-avatar.svg'} />
                  </HStack>
                </MenuButton>
                <MenuList alignItems={'center'} p={4}>
                  <br />
                  <Center>
                    <Avatar size={'xl'} src={'/assets/icons/p-avatar.svg'} />
                  </Center>
                  <br />
                  <Stack>
                    <Text>{dados.nome}</Text>
                    <Box>
                      <Text fontSize={'sm'} fontWeight={500} color={'gray.500'}>
                        Saldo Nacional:{' '}
                      </Text>
                      <Text fontSize={'md'} fontWeight={600} color={'gray.600'}>
                        {Math.ceil(dados.saldoCashClubN) ?? '0'} VX Nacionais
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize={'sm'} fontWeight={500} color={'gray.500'}>
                        Saldo Internacional:{' '}
                      </Text>
                      <Text fontSize={'md'} fontWeight={600} color={'gray.600'}>
                        {Math.ceil(dados.saldoCashClubI) ?? '0'} VX Internacionais
                      </Text>
                    </Box>
                  </Stack>
                  <br />
                  <MenuDivider />

                  <MenuItem
                    onClick={() => {
                      navigate('/panel/dashboard');
                    }}
                  >
                    Painel Associado
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      localStorage.clear();
                      // location.reload(true)
                      localStorage.setItem('language', JSON.stringify('pt-BR'));
                      localStorage.setItem('cotation', JSON.stringify('BRL'));
                      navigate('/');
                    }}
                  >
                    Sair
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Stack>
        </Flex>
      </Container>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const { language, setLanguage } = useLanguage();
  const { cotation, setIsCotation, isCotation } = useCotation();
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  useEffect(() => {
    JSON.parse(localStorage.getItem('language'));
  }, [language]);

  useEffect(() => {
    JSON.parse(localStorage.getItem('cotation'));
  }, [isCotation]);

  return (
    <Stack direction={'row'} spacing={4} display="flex" alignItems="center">
      <HStack spacing={4}>
        <Popover trigger={'hover'} placement={'bottom-start'}>
          <PopoverTrigger>
            <Button
              p={2}
              fontSize={'sm'}
              fontWeight={500}
              color={linkColor}
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}
            >
              Idioma: {language !== undefined && <Image ml="12px" w="20px" src={iconNav(language)} />}
            </Button>
          </PopoverTrigger>

          <PopoverContent border={0} boxShadow={'xl'} bg={popoverContentBgColor} p={4} rounded={'xl'} minW={'sm'}>
            <Stack>
              {/* Menu de linguaguem */}
              <Link
                onClick={() => {
                  localStorage.setItem('language', JSON.stringify('pt-BR'));
                  setLanguage('pt-BR');
                }}
                role={'group'}
                display={'block'}
                p={2}
                rounded={'md'}
                _hover={{ bg: useColorModeValue('red.50', 'gray.900') }}
              >
                <Stack direction={'row'} align={'center'}>
                  <Box>
                    <HStack>
                      <Text transition={'all .3s ease'} _groupHover={{ color: 'red.400' }} fontWeight={500}>
                        Português
                      </Text>
                      <Image ml="12px" w="20px" src="/assets/icons/pt-br.png" />
                    </HStack>

                    <Text fontSize={'sm'}>Selecionar</Text>
                  </Box>

                  <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}
                  >
                    <Icon color={'red.400'} w={5} h={5} as={ChevronRightIcon} />
                  </Flex>
                </Stack>
              </Link>
              <Link
                onClick={() => {
                  localStorage.setItem('language', JSON.stringify('en'));
                  setLanguage('en');
                }}
                role={'group'}
                display={'block'}
                p={2}
                rounded={'md'}
                _hover={{ bg: useColorModeValue('red.50', 'gray.900') }}
              >
                <Stack direction={'row'} align={'center'}>
                  <Box>
                    <HStack>
                      <Text transition={'all .3s ease'} _groupHover={{ color: 'red.400' }} fontWeight={500}>
                        English (USA)
                      </Text>
                      <Image ml="12px" w="20px" src="/assets/icons/eua.png" />
                    </HStack>

                    <Text fontSize={'sm'}>Selecionar</Text>
                  </Box>

                  <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}
                  >
                    <Icon color={'red.400'} w={5} h={5} as={ChevronRightIcon} />
                  </Flex>
                </Stack>
              </Link>
              <Link
                onClick={() => {
                  localStorage.setItem('language', JSON.stringify('es'));
                  setLanguage('es');
                }}
                role={'group'}
                display={'block'}
                p={2}
                rounded={'md'}
                _hover={{ bg: useColorModeValue('red.50', 'gray.900') }}
              >
                <Stack direction={'row'} align={'center'}>
                  <Box>
                    <HStack>
                      <Text transition={'all .3s ease'} _groupHover={{ color: 'red.400' }} fontWeight={500}>
                        Spanish
                      </Text>
                      <Image ml="12px" w="20px" src="/assets/icons/spanish.png" />
                    </HStack>

                    <Text fontSize={'sm'}>Selecionar</Text>
                  </Box>

                  <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}
                  >
                    <Icon color={'red.400'} w={5} h={5} as={ChevronRightIcon} />
                  </Flex>
                </Stack>
              </Link>
            </Stack>
          </PopoverContent>
        </Popover>
        <Popover trigger={'hover'} placement={'bottom-start'}>
          <PopoverTrigger>
            <Button
              p={2}
              fontSize={'sm'}
              fontWeight={500}
              color={linkColor}
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}
            >
              Moeda:
              {cotation !== undefined && <Image w="20px" ml="12px" src={iconCotNav(isCotation)} />}
            </Button>
          </PopoverTrigger>

          <PopoverContent border={0} boxShadow={'xl'} bg={popoverContentBgColor} p={4} rounded={'xl'} minW={'sm'}>
            <Stack>
              {/* Menu de moeda*/}
              <Link
                onClick={() => {
                  localStorage.setItem('cotation', JSON.stringify('BRL'));
                  setIsCotation('BRL');
                }}
                role={'group'}
                display={'block'}
                p={2}
                rounded={'md'}
                _hover={{ bg: useColorModeValue('red.50', 'gray.900') }}
              >
                <Stack direction={'row'} align={'center'}>
                  <Box>
                    <HStack>
                      <Text transition={'all .3s ease'} _groupHover={{ color: 'red.400' }} fontWeight={500}>
                        Reais (BRL)
                      </Text>
                      <Image ml="12px" w="20px" src="/assets/icons/real.png" />
                    </HStack>

                    <Text fontSize={'sm'}>Selecionar</Text>
                  </Box>

                  <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}
                  >
                    <Icon color={'red.400'} w={5} h={5} as={ChevronRightIcon} />
                  </Flex>
                </Stack>
              </Link>
              <Link
                onClick={() => {
                  localStorage.setItem('cotation', JSON.stringify('USD'));
                  setIsCotation('USD');
                }}
                role={'group'}
                display={'block'}
                p={2}
                rounded={'md'}
                _hover={{ bg: useColorModeValue('red.50', 'gray.900') }}
              >
                <Stack direction={'row'} align={'center'}>
                  <Box>
                    <HStack>
                      <Text transition={'all .3s ease'} _groupHover={{ color: 'red.400' }} fontWeight={500}>
                        Dollar (USD)
                      </Text>
                      <Image ml="12px" w="20px" src="/assets/icons/dollar.png" />
                    </HStack>

                    <Text fontSize={'sm'}>Selecionar</Text>
                  </Box>

                  <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}
                  >
                    <Icon color={'red.400'} w={5} h={5} as={ChevronRightIcon} />
                  </Flex>
                </Stack>
              </Link>
              <Link
                onClick={() => {
                  localStorage.setItem('cotation', JSON.stringify('POINTS'));
                  setIsCotation('POINTS');
                }}
                role={'group'}
                display={'block'}
                p={2}
                rounded={'md'}
                _hover={{ bg: useColorModeValue('red.50', 'gray.900') }}
              >
                <Stack direction={'row'} align={'center'}>
                  <Box>
                    <HStack>
                      <Text transition={'all .3s ease'} _groupHover={{ color: 'red.400' }} fontWeight={500}>
                        Viajex
                      </Text>
                      <Image ml="12px" w="20px" src="/assets/icons/cash-club.png" />
                    </HStack>

                    <Text fontSize={'sm'}>Selecionar</Text>
                  </Box>

                  <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}
                  >
                    <Icon color={'red.400'} w={5} h={5} as={ChevronRightIcon} />
                  </Flex>
                </Stack>
              </Link>
            </Stack>
          </PopoverContent>
        </Popover>
      </HStack>

      {NAV_ITEMS_DESK.map(navItem => (
        <Box key={navItem.label}>
          <Link
            p={2}
            href={navItem.href ?? '#'}
            fontSize={'sm'}
            target="_blank"
            fontWeight={500}
            color={linkColor}
            _hover={{
              textDecoration: 'none',
              color: linkHoverColor,
            }}
          >
            {navItem.label}
          </Link>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = () => {
  const { language, setLanguage } = useLanguage();
  const { cotation, setIsCotation, isCotation } = useCotation();
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  const { onClose } = useDisclosure();

  useEffect(() => {
    JSON.parse(localStorage.getItem('language'));
  }, [language]);

  useEffect(() => {
    JSON.parse(localStorage.getItem('cotation'));
  }, [isCotation]);
  return (
    <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }}>
      <HStack spacing={4}>
        <Popover trigger={'click'} placement={'bottom-start'} closeOnBlur={true}>
          <PopoverTrigger>
            <Button
              p={2}
              fontSize={'sm'}
              fontWeight={500}
              color={linkColor}
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}
            >
              Idioma: {language !== null && <Image ml="12px" w="20px" src={iconNav(language)} />}
            </Button>
          </PopoverTrigger>

          <PopoverContent border={0} boxShadow={'xl'} bg={popoverContentBgColor} p={4} rounded={'xl'} minW={'sm'}>
            <PopoverCloseButton />
            <PopoverBody>
              <Stack>
                {/* Menu de linguaguem */}
                <Link
                  onClick={() => {
                    localStorage.setItem('language', JSON.stringify('pt-BR'));
                    setLanguage('pt-BR');
                  }}
                  role={'group'}
                  display={'block'}
                  p={2}
                  rounded={'md'}
                  _hover={{ bg: useColorModeValue('red.50', 'gray.900') }}
                >
                  <Stack direction={'row'} align={'center'}>
                    <Box>
                      <HStack onClick={onClose}>
                        <Text transition={'all .3s ease'} _groupHover={{ color: 'red.400' }} fontWeight={500}>
                          Português
                        </Text>
                        <Image ml="12px" w="20px" src="/assets/icons/pt-br.png" />
                      </HStack>

                      <Text fontSize={'sm'}>Selecionar</Text>
                    </Box>

                    <Flex
                      transition={'all .3s ease'}
                      transform={'translateX(-10px)'}
                      opacity={0}
                      _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                      justify={'flex-end'}
                      align={'center'}
                      flex={1}
                    >
                      <Icon color={'red.400'} w={5} h={5} as={ChevronRightIcon} />
                    </Flex>
                  </Stack>
                </Link>
                <Link
                  onClick={() => {
                    localStorage.setItem('language', JSON.stringify('en'));
                    setLanguage('en');
                  }}
                  role={'group'}
                  display={'block'}
                  p={2}
                  rounded={'md'}
                  _hover={{ bg: useColorModeValue('red.50', 'gray.900') }}
                >
                  <Stack direction={'row'} align={'center'}>
                    <Box>
                      <HStack>
                        <Text transition={'all .3s ease'} _groupHover={{ color: 'red.400' }} fontWeight={500}>
                          English (USA)
                        </Text>
                        <Image ml="12px" w="20px" src="/assets/icons/eua.png" />
                      </HStack>

                      <Text fontSize={'sm'}>Selecionar</Text>
                    </Box>

                    <Flex
                      transition={'all .3s ease'}
                      transform={'translateX(-10px)'}
                      opacity={0}
                      _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                      justify={'flex-end'}
                      align={'center'}
                      flex={1}
                    >
                      <Icon color={'red.400'} w={5} h={5} as={ChevronRightIcon} />
                    </Flex>
                  </Stack>
                </Link>
                <Link
                  onClick={() => {
                    localStorage.setItem('language', JSON.stringify('es'));
                    setLanguage('es');
                  }}
                  role={'group'}
                  display={'block'}
                  p={2}
                  rounded={'md'}
                  _hover={{ bg: useColorModeValue('red.50', 'gray.900') }}
                >
                  <Stack direction={'row'} align={'center'}>
                    <Box>
                      <HStack>
                        <Text transition={'all .3s ease'} _groupHover={{ color: 'red.400' }} fontWeight={500}>
                          Spanish
                        </Text>
                        <Image ml="12px" w="20px" src="/assets/icons/spanish.png" />
                      </HStack>

                      <Text fontSize={'sm'}>Selecionar</Text>
                    </Box>

                    <Flex
                      transition={'all .3s ease'}
                      transform={'translateX(-10px)'}
                      opacity={0}
                      _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                      justify={'flex-end'}
                      align={'center'}
                      flex={1}
                    >
                      <Icon color={'red.400'} w={5} h={5} as={ChevronRightIcon} />
                    </Flex>
                  </Stack>
                </Link>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Popover trigger={'click'} placement={'bottom-start'}>
          <PopoverTrigger>
            <Button
              onClick={() => {
                localStorage.setItem('language', JSON.stringify('pt-BR'));
                setLanguage('pt-BR');
              }}
              p={2}
              fontSize={'sm'}
              fontWeight={500}
              color={linkColor}
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}
            >
              Moeda:
              {cotation !== null && <Image w="20px" ml="12px" src={iconCotNav(isCotation)} />}
            </Button>
          </PopoverTrigger>

          <PopoverContent border={0} boxShadow={'xl'} bg={popoverContentBgColor} p={4} rounded={'xl'} minW={'sm'}>
            <PopoverCloseButton />
            <PopoverBody>
              <Stack>
                {/* Menu de moeda*/}
                <Link
                  onClick={() => {
                    localStorage.setItem('cotation', JSON.stringify('BRL'));
                    setIsCotation('BRL');
                  }}
                  role={'group'}
                  display={'block'}
                  p={2}
                  rounded={'md'}
                  _hover={{ bg: useColorModeValue('red.50', 'gray.900') }}
                >
                  <Stack direction={'row'} align={'center'}>
                    <Box>
                      <HStack>
                        <Text transition={'all .3s ease'} _groupHover={{ color: 'red.400' }} fontWeight={500}>
                          Reais (BRL)
                        </Text>
                        <Image ml="12px" w="20px" src="/assets/icons/real.png" />
                      </HStack>

                      <Text fontSize={'sm'}>Selecionar</Text>
                    </Box>

                    <Flex
                      transition={'all .3s ease'}
                      transform={'translateX(-10px)'}
                      opacity={0}
                      _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                      justify={'flex-end'}
                      align={'center'}
                      flex={1}
                    >
                      <Icon color={'red.400'} w={5} h={5} as={ChevronRightIcon} />
                    </Flex>
                  </Stack>
                </Link>
                <Link
                  onClick={() => {
                    localStorage.setItem('cotation', JSON.stringify('USD'));
                    setIsCotation('USD');
                  }}
                  role={'group'}
                  display={'block'}
                  p={2}
                  rounded={'md'}
                  _hover={{ bg: useColorModeValue('red.50', 'gray.900') }}
                >
                  <Stack direction={'row'} align={'center'}>
                    <Box>
                      <HStack>
                        <Text transition={'all .3s ease'} _groupHover={{ color: 'red.400' }} fontWeight={500}>
                          Dollar (USD)
                        </Text>
                        <Image ml="12px" w="20px" src="/assets/icons/dollar.png" />
                      </HStack>

                      <Text fontSize={'sm'}>Selecionar</Text>
                    </Box>

                    <Flex
                      transition={'all .3s ease'}
                      transform={'translateX(-10px)'}
                      opacity={0}
                      _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                      justify={'flex-end'}
                      align={'center'}
                      flex={1}
                    >
                      <Icon color={'red.400'} w={5} h={5} as={ChevronRightIcon} />
                    </Flex>
                  </Stack>
                </Link>
                <Link
                  onClick={() => {
                    localStorage.setItem('cotation', JSON.stringify('POINTS'));
                    setIsCotation('POINTS');
                  }}
                  role={'group'}
                  display={'block'}
                  p={2}
                  rounded={'md'}
                  _hover={{ bg: useColorModeValue('red.50', 'gray.900') }}
                >
                  <Stack direction={'row'} align={'center'}>
                    <Box>
                      <HStack>
                        <Text transition={'all .3s ease'} _groupHover={{ color: 'red.400' }} fontWeight={500}>
                          Viajex
                        </Text>
                        <Image ml="12px" w="20px" src="/assets/icons/cash-club.png" />
                      </HStack>

                      <Text fontSize={'sm'}>Selecionar</Text>
                    </Box>

                    <Flex
                      transition={'all .3s ease'}
                      transform={'translateX(-10px)'}
                      opacity={0}
                      _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                      justify={'flex-end'}
                      align={'center'}
                      flex={1}
                    >
                      <Icon color={'red.400'} w={5} h={5} as={ChevronRightIcon} />
                    </Flex>
                  </Stack>
                </Link>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>
      {NAV_ITEMS_MOBILE.map(navItem => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, href, target }) => {
  return (
    <Stack spacing={4}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        target={target ?? '_blank'}
        justify={'space-between'}
        align={'center'}
        fontWeight={600}
        color={useColorModeValue('gray.600', 'gray.200')}
        _hover={{
          textDecoration: 'none',
        }}
      >
        {label}
      </Flex>
    </Stack>
  );
};

const NAV_ITEMS_DESK = [
  {
    label: 'Contato',
    href: 'mailto:atendimento@clubshare.com.br',
    target: '_blank',
    type: 'Link',
  },
  {
    label: 'Ajuda',
    href: 'https://wa.me/556230892500',
    target: '_blank',
    type: 'Link',
  },
];

const NAV_ITEMS_MOBILE = [
  {
    label: 'Contato',
    href: 'mailto:atendimento@clubshare.com.br',
    target: '_blank',
    type: 'Link',
  },
  {
    label: 'Ajuda',
    href: 'https://wa.me/556230892500',
    target: '_blank',
    type: 'Link',
  },
];
