import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Center,
  GridItem,
  LinkBox,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  Flex,
  TagLabel,
  Text,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import BedroomDetails from '../../pages/BedRoomDetails';
import { translates } from '../../utils/languague';
import { useLanguage } from '../../providers/LanguageProvider';
import { useCotation } from '../../providers/CotationProvider';
import { BiCalendar, BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { FaArrowRight } from 'react-icons/fa';
import { FiUserPlus } from 'react-icons/fi';
import { useSearch } from '../../providers/SearchProvider';
import moment from 'moment';
import { theMonthAbb, theWeekDay } from '../../utils/scheduleFormat';
import { useNavigate } from 'react-router-dom';
import { MdTouchApp } from 'react-icons/md';

export default function BedRoomCard({ title = '', dataCommons, dataCangoroo }) {
  const { sR, selectedDates, nNights } = useSearch();
  const { cotation, isCotation, points, markupC } = useCotation();
  const { language } = useLanguage();
  const navigate = useNavigate();

  const [showDetails, setShowDetails] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const filterMedia = () => {
    if (dataCommons !== []) {
      const itemRoom = dataCommons?.filter(item => item.code === dataCangoroo?.MediaRoomId);
      return itemRoom;
    }
    return [];
  };

  useEffect(() => {
    filterMedia();
  }, [dataCommons !== []]);

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('__user_data')) {
      setUserData(JSON.parse(localStorage.getItem('__user_data')));
    }
  }, []);

  const typeCotation = () => {
    if (isCotation == 'USD') {
      const base = dataCangoroo?.TotalSellingPrice?.Value;
      const cot = cotation;
      const calc = base / cot;
      const cotMarkup = calc * (markupC / 100);
      const result = Math.ceil(cotMarkup + calc);
      return result?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    if (isCotation == 'BRL') {
      return dataCangoroo?.TotalSellingPrice?.Value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }
    if (isCotation == 'POINTS') {
      const base = dataCangoroo?.TotalSellingPrice?.Value;
      const cot = points;
      const calc = Math.ceil(base * cot);
      return calc;
    }
    return dataCangoroo?.TotalSellingPrice?.Value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  };

  const noImg = '/assets/logo.svg';

  const removeTags = str => {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
  };

  const OverlayOne = () => <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const [openExchange, setOpenExchange] = React.useState(false);

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const carouselItemsRef = useRef([]);

  useEffect(() => {
    if (filterMedia()[0]?.photos && filterMedia()[0]?.photos[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(0, filterMedia()[0]?.photos.length);

      setSelectedImageIndex(0);
      setSelectedImage(filterMedia()[0]?.photos[0]);
    }
  }, [filterMedia()[0]?.photos]);

  const handleSelectedImageChange = (newIdx: number) => {
    if (filterMedia()[0]?.photos && filterMedia()[0]?.photos.length > 0) {
      setSelectedImage(filterMedia()[0]?.photos[newIdx]);
      setSelectedImageIndex(newIdx);
    }
  };

  const handleRightClick = () => {
    if (filterMedia()[0]?.photos && filterMedia()[0]?.photos.length > 0) {
      let newIdx = selectedImageIndex + 1;
      if (newIdx >= filterMedia()[0]?.photos.length) {
        newIdx = 0;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  const handleLeftClick = () => {
    if (filterMedia()[0]?.photos && filterMedia()[0]?.photos.length > 0) {
      let newIdx = selectedImageIndex - 1;
      if (newIdx < 0) {
        newIdx = filterMedia()[0]?.photos.length - 1;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  return (
    <>
      {dataCangoroo !== [] && dataCangoroo?.TotalSellingPrice?.Value && (
        <GridItem
          display="flex"
          alignItems={['center', 'flex-start']}
          mb={['20px', 0]}
          borderRadius="5px"
          w="100%"
          bg={['theme.white', 'transparent']}
          flexDirection={['column', 'row']}
        >
          <Box
            w={'30vw'}
            objectFit="contain"
            h="100%"
            borderRadius={['10px 10px 0px 0px', '10px 0px 0px 10px']}
            className="selected-image"
            style={{
              backgroundImage: `url(${selectedImage?.url ?? noImg})`,
              backgroundSize: !selectedImage?.url && 'auto',
              backgroundColor: selectedImage?.url ? 'transparent' : '#E2E8F0',
            }}
          >
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <button
                  className="carousel__button carousel__button-left"
                  onClick={handleLeftClick}
                  style={{ borderRadius: '50%', backgroundColor: 'white', position: 'relative' }}
                >
                  <BiChevronLeft size={40} color="black" />
                </button>
              </div>
              <div>
                <button
                  className="carousel__button carousel__button-right"
                  onClick={handleRightClick}
                  style={{ borderRadius: '50%', backgroundColor: 'white', position: 'relative' }}
                >
                  <BiChevronRight size={40} color="black" />
                </button>
              </div>
            </div>
          </Box>
          <LinkBox position="absolute" onClick={onOpen} style={{ cursor: 'pointer' }}>
            <Box
              align="center"
              visibility={['hidden', 'visible']}
              display="flex"
              h="35px"
              flexDir="row"
              borderRadius="5px"
              bg="black"
              p="10px"
            >
              <Center>
                <Text fontWeight="bold" color="theme.white" mr="10px">
                  Ver detalhes
                </Text>
                <MdTouchApp color="white" />
              </Center>
            </Box>
          </LinkBox>

          <Box
            minH={['100%', '320px']}
            display="flex"
            flexDir={['column', 'row']}
            bg={['transparent', 'theme.white']}
            justifyContent={['flex-start', 'space-between']}
            borderRadius={['0px 0px 10px 10px', '0px 10px 10px 0px']}
            w="100%"
            p="20px"
          >
            <Box>
              <Text fontSize="20px" fontWeight="medium">
                {title}
              </Text>
              {dataCangoroo && dataCangoroo?.CancellationPolicies && dataCangoroo.CancellationPolicies[0] && (
                <>
                  {console.log('Entrou no bloco de cancelamento', dataCangoroo.CancellationPolicies)}
                  <Text mt="10px" fontSize="14px">
                    Cancelamento sem custo até o dia <br />
                    <Box
                      as="span"
                      color={
                        moment(dataCangoroo?.CancellationPolicies[0]?.StartDate).isAfter(moment()) ? 'green' : 'red'
                      }
                      fontWeight="bold"
                    >
                      {moment(dataCangoroo?.CancellationPolicies[0]?.StartDate).format('LLLL')}
                    </Box>
                    <br /> pelo valor de
                    <Box as="span" color="red" fontWeight="bold">
                      {Math.ceil(dataCangoroo?.CancellationPolicies[0]?.Value?.Value).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Box>
                    <br />
                    <Box as="span" color="red" fontSize="12px" fontWeight="bold">
                      *Não aplicável para pagamentos em Viajex
                    </Box>
                  </Text>
                </>
              )}

              {filterMedia()?.length > 0 && (
                <List w="80%" mt="20px">
                  {showDetails ? (
                    <ListItem>
                      {filterMedia()?.length > 0 ? removeTags(filterMedia()[0]?.description[language ?? 'pt-BR']) : ''}
                    </ListItem>
                  ) : (
                    <ListItem>
                      {filterMedia()?.length > 0
                        ? `${removeTags(filterMedia()[0]?.description[language ?? 'pt-BR'])?.substring(0, 50)} ...`
                        : ''}
                    </ListItem>
                  )}
                  <Text
                    mt="10px"
                    cursor="pointer"
                    textDecoration="underline"
                    fontWeight="semibold"
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    {showDetails ? 'Ver menos detalhes' : 'Ver mais detalhes'}
                  </Text>
                </List>
              )}
              {filterMedia()?.length > 0 && (
                <>
                  <Text mt="10px" mb="15px" fontSize="18px" fontWeight="bold">
                    Comodidades do quarto:
                  </Text>
                  <Box w="80%">
                    {filterMedia()[0]
                      ?.amenities.map(a => a?.name[language])
                      ?.map((tag, idx) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Tag m="5px" size="lg" key={idx} variant="outline" colorScheme="blackAlpha">
                          <TagLabel>{tag}</TagLabel>
                        </Tag>
                      ))}
                  </Box>
                </>
              )}
            </Box>
            <Box
              alignItems="flex-end"
              justifyContent="space-evenly"
              display="flex"
              mt={['10px', 0]}
              flexDir={['row', 'column']}
            >
              <Box display="flex" flexDir={['column']} justifySelf={['start', 'end']}>
                <Text textAlign={['start', 'end']} color="theme.celta">
                  {translates(language)?.cards?.item6}
                </Text>
                <Box alignItems="center" display="flex" flexDir="row" alignSelf={['start', 'end']}>
                  <Text textAlign={['start', 'end']} fontWeight="bold" fontSize={['16px', '22px']}>
                    {dataCangoroo !== undefined ? typeCotation() : 'Preço não informado'}
                  </Text>
                  {isCotation == 'POINTS' && <Image ml="5px" h="30px" src="/assets/icons/cash-club.png" />}
                </Box>
              </Box>

              <Button
                onClick={() => {
                  setOverlay(<OverlayOne />);
                  setOpenExchange(!openExchange);
                }}
                w={['150px']}
                colorScheme={'red'}
              >
                {translates(language)?.cards?.item7}
              </Button>
              <Modal blockScrollOnMount={false} isOpen={openExchange} onClose={() => setOpenExchange(!openExchange)}>
                {/* {overlay} */}
                <ModalContent bg="#000" maxW="100vw" minH="130px" alignSelf="flex-end" m="0" bottom={0}>
                  <ModalBody>
                    <Flex
                      align="center"
                      alignContent="center"
                      alignItems="center"
                      alignSelf="center"
                      justify="center"
                      justifySelf="center"
                      justifyContent="center"
                      justifyItems="center"
                      h="130px"
                    >
                      <Flex
                        align="center"
                        alignContent="center"
                        alignItems="center"
                        alignSelf="center"
                        justify="center"
                        justifySelf="center"
                        justifyContent="center"
                        justifyItems="center"
                        flexDir="row"
                      >
                        <Flex flexDir="column">
                          <Text fontWeight="bold" fontSize="16px" color="white">
                            {translates(language)?.cards?.item11}
                          </Text>
                          <Flex
                            align="center"
                            alignContent="center"
                            alignItems="center"
                            alignSelf="center"
                            justify="center"
                            justifySelf="center"
                            justifyContent="center"
                            justifyItems="center"
                            flexDir="row"
                          >
                            <BiCalendar size={25} color="white" />
                            <Text fontWeight="bold" fontSize="24px" color="white">
                              {moment(selectedDates[0])?.format('DD')}
                            </Text>
                            <Flex
                              align="center"
                              alignContent="center"
                              alignItems="center"
                              alignSelf="center"
                              justify="center"
                              justifySelf="center"
                              justifyContent="center"
                              justifyItems="center"
                              flexDir="column"
                              mr="20px"
                              ml="20px"
                            >
                              <Text fontWeight="medium" fontSize="12px" color="white">
                                {theWeekDay(selectedDates[0])?.toLocaleUpperCase()}
                              </Text>
                              <Text fontWeight="medium" fontSize="12px" color="white">
                                {theMonthAbb(selectedDates[0])?.toLocaleUpperCase()}
                              </Text>
                            </Flex>
                            <FaArrowRight size={25} color="white" />
                            <Text ml="20px" fontWeight="bold" fontSize="24px" color="white">
                              {moment(selectedDates[1])?.format('DD')}
                            </Text>
                            <Flex flexDir="column" mr="20px" ml="20px">
                              <Text fontWeight="medium" fontSize="12px" color="white">
                                {theWeekDay(selectedDates[1])?.toLocaleUpperCase()}
                              </Text>
                              <Text fontWeight="medium" fontSize="12px" color="white">
                                {theMonthAbb(selectedDates[1])?.toLocaleUpperCase()}
                              </Text>
                            </Flex>
                            <FiUserPlus size={25} color="white" />

                            <Flex ml="5px" flexDir="column">
                              <Text fontWeight="medium" fontSize="12px" color="white">
                                {nNights} {nNights <= 1 ? 'diária' : 'diárias'} - {sR.length}{' '}
                                {sR.length <= 1 ? 'quarto' : 'quartos'}
                              </Text>
                              {sR?.map(item => (
                                <Text key="a" fontWeight="medium" fontSize="12px" color="white">
                                  {item?.numberOfAdults} {item?.numberOfAdults <= 1 ? 'adulto' : 'adultos'}
                                </Text>
                              ))}

                              {sR?.map(
                                item =>
                                  item?.childrenAge?.length > 0 && (
                                    <>
                                      <Text key="a" fontWeight="medium" fontSize="12px" color="white">
                                        {item?.childrenAge?.length}{' '}
                                        {item?.childrenAge?.length <= 1 ? 'criança' : 'crianças'}
                                      </Text>
                                    </>
                                  ),
                              )}
                            </Flex>
                          </Flex>
                        </Flex>
                        <Box bg="white" w="2px" height="80px" mr="20px" ml="20px" />
                        <Text fontSize="36px" fontWeight="bold" color="white">
                          {dataCangoroo !== undefined ? typeCotation() : 'Preço não informado'}
                        </Text>
                        <Button
                          ml="20px"
                          onClick={() => {
                            if (userData?.user_id) {
                              navigate('/checkout', {
                                state: { itemCheckout: filterMedia(), dataCangoroo: dataCangoroo, sR: sR },
                              });
                              window.scrollTo(0, 0);
                            } else {
                              navigate('/login', {
                                state: { isBacktoHotel: true },
                              });
                            }
                          }}
                          w={['265px']}
                          h="75px"
                          fontSize="20px"
                          fontWeight="bold"
                        >
                          {translates(language)?.cards?.item8}
                        </Button>
                      </Flex>
                    </Flex>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
          </Box>
        </GridItem>
      )}
      {isOpen && (
        <Modal blockScrollOnMount scrollBehavior="inside" size="6xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <BedroomDetails
                image={
                  filterMedia()[0]?.photos.length >= 1
                    ? filterMedia()[0]?.photos
                    : [{ url: 'https://www.emporiodocouroedesign.com.br/wp-content/uploads/2018/06/image_large.png' }]
                }
                title={dataCangoroo !== [] && dataCangoroo?.BoardDescription}
                textDescription={
                  dataCangoroo?.MoreInformation
                    ? dataCangoroo?.MoreInformation
                    : 'Detalhes ainda não informado pelo empreendimento, mais informações entre em contato com o suporte Club Share'
                }
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
