import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, Input, Select, Text, useToast } from '@chakra-ui/react';
import ReactTable from '../../components/ReactTable';
import { ReturnButton } from '../../components/Buttons/ReturnButton';
import { BiFilter } from 'react-icons/bi';
import { VscEdit } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import IUsers from '../../interfaces/IUsers';
import { apiCPT } from '../../services/api';
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton';
import ConfirmModal from '../../components/ConfirmModal';

const Users = () => {
  // const baseUrl = '/users'
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [data, setData] = useState<IUsers[]>([]);

  // const [isOpen, onOpen] = useState(true)

  const toast = useToast();

  useEffect(() => {
    getUsers();
  }, [pageNumber, perPage]);

  const getUsers = async () => {
    try {
      const getUsers = await apiCPT.get(`v1/users?pageNumber=${pageNumber}&perPage=${perPage}`);

      if (getUsers.status === 200) {
        setData(getUsers.data.users);
        setPageSize(getUsers.data.paginateCount);
        // setTotal(getUsers.data.count)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nome',
        columns: [
          {
            Header: 'Primeiro nome',
            accessor: 'user_firstname',
          },
          {
            Header: 'Sobrenome',
            accessor: 'user_lastname',
          },
        ],
      },
      {
        Header: 'Info',
        columns: [
          {
            Header: 'CPF',
            accessor: 'user_cpf',
          },
          {
            Header: 'Telefone',
            accessor: 'user_telephone',
          },
          {
            Header: 'Status',
            accessor: 'user_active',
            Cell: ({ row }: any) => {
              return row.original.user_active === '1' ? (
                <Text color="green.500">Ativo</Text>
              ) : (
                <Text color="red.500">Inativo</Text>
              );
            },
          },
          {
            Header: 'E-mail',
            accessor: 'email',
          },
          {
            Header: 'Ações',
            accessor: 'actions',
            Cell: ({ row }: any) => (
              <Flex align="center">
                <IconGeneralButton
                  Icon={VscEdit}
                  color="#1890FF"
                  fontSize="25px"
                  onClick={() => {
                    navigate(`${row.original.user_id}/edit`);
                  }}
                />
                {ConfirmModal(() =>
                  toast({
                    title: `Usuário sem permisão para excluir o usuário ${row.original.user_firstname}`,
                    position: 'top',
                    status: 'error',
                    isClosable: true,
                  }),
                )}
              </Flex>
            ),
          },
        ],
      },
    ],
    [],
  );

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box display="flex" flexDir="column">
        <Box
          display="flex"
          alignContent="center"
          justifyItems="center"
          justifySelf="center"
          alignItems="center"
          flexDir="row"
          mb="20px"
        >
          {/* <ResumeCard title="Todos usuários" icon={<BiUser color="#fff" />} subititle={`${total}`} /> */}
          <Box w="80px" />
          {/* <ResumeCard
            title="Clientes"
            icon={<BiWallet color="#fff" />}
            subititle={data.filter(e => e.user_type === '3').length.toString()}
          /> */}
          <Box w="80px" />
          <Button onClick={() => navigate('v1/users/create')} colorScheme="red" variant="solid">
            Adicionar usuário +
          </Button>
        </Box>
      </Box>
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
          Usuários
        </Text>
        <Flex flexDir="column" mb="40px">
          <Box display="flex" flexDir="row">
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                ID
              </Text>
              <Input placeholder="Ex: 589" />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Nome
              </Text>
              <Input placeholder="Ex: Paulo" />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Sobrenome
              </Text>
              <Input placeholder="Ex: Mateus" />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Email
              </Text>
              <Input type="email" placeholder="Ex: endereço@email.com" />
            </Box>
          </Box>
          <Box mt="20px" display="flex" flexDir="row">
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Status
              </Text>
              <Select placeholder="Selecione ">
                <option value="option1">Opção 1</option>
                <option value="option2">Opção 2</option>
                <option value="option3">Opção 3</option>
              </Select>
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Grupo
              </Text>
              <Select placeholder="Selecione ">
                <option value="option1">Opção 1</option>
                <option value="option2">Opção 2</option>
                <option value="option3">Opção 3</option>
              </Select>
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Data de criação
              </Text>
              <Input type="date" />
            </Box>
            <Box display="flex" alignContent="end" alignItems="end">
              <Button leftIcon={<BiFilter />} colorScheme="red" variant="solid">
                Filtrar
              </Button>
            </Box>
          </Box>
        </Flex>
        <Box>
          <Flex align="center" mb={5}>
            <ReturnButton previousPage="panel/dashboard" />

            <Heading as="h1" size="lg">
              Lista de usuários
            </Heading>
          </Flex>

          <ReactTable
            columns={columns}
            pageNumber={pageNumber}
            data={data}
            totaPages={pageSize}
            onChange={(e: any) => {
              setPageNumber(e.pageIndex + 1);
              setPerPage(e.pageSize);
            }}
          />
        </Box>
      </Box>
    </Flex>
  );
};
export default Users;
